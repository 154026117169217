import {
  APP_VERSION_B2C,
  B2C_DASHBOARD_FILTERS,
  FEDERAL_STATES,
} from "@recare/core/consts";
import RootPage from "containers/RootPage";
import { APP_BACKGROUND } from "ds/materials/colors";
import { usePersistQueryParamProviderSearch } from "dsl/molecules/ProviderSearchAppWrapper/usePersistProviderSearchFilters";
import { initialStateB2C } from "dsl/organisms/Filters/B2CApp/initialState";
import {
  B2CFilterActions,
  B2CFilters,
} from "dsl/organisms/Filters/B2CApp/types";
import { persistReducerFunction } from "dsl/organisms/Filters/utils/persistFilterReducer";
import { ReactNode } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import styled from "styled-components";
import Footer from "./Footer";
import Header from "./Header";

const Page = styled.div`
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: ${APP_BACKGROUND};
  overflow-x: hidden;
`;

export const AppBarWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column;
`;

export type B2CHeaderContext = {
  filters: B2CFilters;
  setFilters: React.Dispatch<B2CFilterActions>;
};

export function useB2CHeaderContext() {
  return useOutletContext<B2CHeaderContext>();
}

export default function B2CHeader({
  children,
}: {
  children?: (props: B2CHeaderContext) => ReactNode;
}) {
  const [filters, setFilters] = usePersistQueryParamProviderSearch(
    B2C_DASHBOARD_FILTERS,
    persistReducerFunction,
    initialStateB2C,
    FEDERAL_STATES.NIEDERSACHSEN,
  );

  return (
    <RootPage appName={APP_VERSION_B2C}>
      <Page>
        <Header filters={filters} />
        <AppBarWrapper>
          {children ? (
            children({ filters, setFilters })
          ) : (
            <Outlet context={{ filters, setFilters }} />
          )}
        </AppBarWrapper>
        <Footer />
      </Page>
    </RootPage>
  );
}
