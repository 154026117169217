import {
  CARESEEKER_TAB_TYPE_PATIENTS,
  PATIENT_TAB_FILES,
  PATIENT_TAB_INSURANCE,
  PATIENT_TAB_MAP,
  PATIENT_TAB_PROFILE,
  PATIENT_TAB_REHAB_FORMS,
  PATIENT_TAB_SEARCH,
  PATIENT_TAB_TIMELINE,
  PATIENT_TAB_TRANSITIONAL_CARE,
  REDIRECT_PARAM,
} from "@recare/core/consts";
import { AssessmentSlug, MessengerTabKinds } from "@recare/core/types";
import { useTranslations } from "@recare/translations";
import { removeParam } from "dsl/hooks/useRemoveQueryParam";
import { PatientTab } from "dsl/organisms/PatientNavigation";
import { generateParams } from "entries/receiver/Router/helpers";
import {
  Location,
  NavigateFunction,
  NavigateOptions,
  Path,
  useLocation,
  useNavigate,
} from "react-router-dom";

const dashboardRoute = "/app/clinic/dashboard";

function isPath(value: Partial<Path> | string): value is Path {
  return !!(value as Partial<Path>)?.pathname;
}

export function isExitingAssessment(
  pathname: string,
  nextPathname: Partial<Path> | string,
) {
  if (!pathname.includes("/assessing")) return false;
  const patientId = pathname.match(/\/patient\/(.*)\/assessing/)?.[1];

  const nextPath = isPath(nextPathname)
    ? nextPathname.pathname
    : (nextPathname as string);

  return !nextPath.includes(`/patient/${patientId}`);
}

export function checkAuctionRoute({ pathname }: { pathname: string }) {
  // check if user is in auction route
  const regex = /patient\/+(\d+)+\/auction\/+(\d+)\//;
  return regex.test(pathname);
}

export function navigateBetweenPatientAuctions({
  auctionId,
  pathname,
}: {
  auctionId: number;
  pathname: string;
}) {
  return pathname.replace(/auction\/+(\d+)\//, `auction/${auctionId}/`);
}

export function useEnhancedNavigate(): NavigateFunction {
  const location = useLocation();
  const translations = useTranslations();
  const navigate = useNavigate();

  const enhancedNavigate = (nextLocation: Path, options?: NavigateOptions) => {
    const to = removeParam("ref", nextLocation);

    if (isExitingAssessment(location.pathname, to)) {
      if (window.confirm(translations.patient.warningLeavingAssessment)) {
        return navigate(to, options);
      }
      return null;
    }
    return navigate(to, options);
  };

  return enhancedNavigate as NavigateFunction;
}

const goToPatientHomeHandlers = ({
  navigate,
  search,
}: {
  navigate: NavigateFunction;
  search: string;
}) => {
  const goToPatientHomeTab = ({
    auctionId,
    patientId,
    tab,
  }: {
    auctionId: number;
    patientId: number;
    tab: PatientTab;
  }) => {
    navigate({
      pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/${tab}`,
      search,
    });
  };

  return {
    goToSearch(patientId: number, auctionId: number) {
      goToPatientHomeTab({
        auctionId,
        patientId,
        tab: PATIENT_TAB_SEARCH,
      });
    },
    goToProfile(patientId: number, auctionId: number) {
      goToPatientHomeTab({
        auctionId,
        patientId,
        tab: PATIENT_TAB_PROFILE,
      });
    },
    goToInsurance(patientId: number, auctionId: number) {
      goToPatientHomeTab({
        auctionId,
        patientId,
        tab: PATIENT_TAB_INSURANCE,
      });
    },
    goToDocumentation(patientId: number, auctionId: number) {
      goToPatientHomeTab({
        auctionId,
        patientId,
        tab: PATIENT_TAB_TIMELINE,
      });
    },
    goToFiles(patientId: number, auctionId: number) {
      goToPatientHomeTab({ auctionId, patientId, tab: PATIENT_TAB_FILES });
    },
    goToMap(patientId: number, auctionId: number) {
      goToPatientHomeTab({ auctionId, patientId, tab: PATIENT_TAB_MAP });
    },
    goToTransitionalCare(patientId: number, auctionId: number) {
      goToPatientHomeTab({
        auctionId,
        patientId,
        tab: PATIENT_TAB_TRANSITIONAL_CARE,
      });
    },
    goToEligibilityForm(patientId: number, auctionId: number) {
      goToPatientHomeTab({
        auctionId,
        patientId,
        tab: PATIENT_TAB_REHAB_FORMS,
      });
    },
    goToPatientHomeTab,
  };
};

export function getCareseekerNavigationHandlers({
  location,
  navigate,
}: {
  location: Location;
  navigate: NavigateFunction;
}) {
  const { search } = location;

  return {
    changePassword() {
      navigate({
        pathname: `${dashboardRoute}/my_password_change`,
        search: `${REDIRECT_PARAM}=${encodeURIComponent(
          `${location.pathname}${location.search}`,
        )}`,
      });
    },
    goToTodo(tabName?: string) {
      navigate({
        pathname: `${dashboardRoute}/todo/${
          tabName ? `${tabName}` : CARESEEKER_TAB_TYPE_PATIENTS
        }`,
        search: "",
      });
    },
    goToReport() {
      navigate({
        pathname: `${dashboardRoute}/report`,
        search,
      });
    },
    goToUsers() {
      navigate({
        pathname: `${dashboardRoute}/users`,
        search,
      });
    },
    goToOnPremise(page: string, caseId?: string) {
      navigate({
        pathname: `${dashboardRoute}/onpremsetup/${page}${
          caseId ? `?external_id=${caseId}` : ""
        }`,
      });
    },
    patient: {
      new(external_id?: string, origin?: string) {
        navigate({
          pathname: `${dashboardRoute}/patient/new`,
          search: `${
            external_id
              ? `external_id=${external_id}${origin ? `&origin=${origin}` : ""}`
              : ""
          }`,
        });
      },
      goToAssessing(patientId: number, auctionId: number) {
        navigate({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/assessing/transfer`,
          search,
        });
      },
      goToassessmentSlug(
        patientId: number,
        auctionId: number,
        assessmentSlug?: AssessmentSlug,
        sessionId?: string,
      ) {
        navigate({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/assessing/${
            assessmentSlug ?? ""
          }`,
          search: `${sessionId ? `session_id=${sessionId}` : ""}`,
        });
      },

      goToBarthelIndexDetails({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        navigate({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/barthel-index`,
          search,
        });
      },
      goToEarlyRehabIndexDetails({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId: number;
      }) {
        navigate({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/early-rehab-index`,
          search,
        });
      },
      goToHome(patientId: number, auctionId?: number) {
        const auctionParam = auctionId ? `/auction/${auctionId}` : "";
        navigate(
          {
            pathname: `${dashboardRoute}/patient/${patientId}${auctionParam}`,
            search,
          },
          {
            state: {
              prevLocation: encodeURIComponent(
                `${location.pathname}${location.search}`,
              ),
            },
          },
        );
      },
      goToRequest(
        requestId: number,
        patientId: number,
        auctionId: number,
        params?: {
          activeTab?: MessengerTabKinds;
          recommendation?: boolean;
        },
      ) {
        if (requestId <= 0) {
          console.error(
            `[DEV-14068] [goToRequest] tried to access bad request id ${requestId}`,
          );
          return;
        }

        const recommendationParam = params?.recommendation ? "true" : undefined;

        const searchParams =
          generateParams([
            ["activeTab", params?.activeTab],
            ["recommendation", recommendationParam],
          ]) ?? "";

        navigate({
          pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/request/${requestId}`,
          search: searchParams,
        });
      },
      goToAuction({
        auctionId,
        patientId,
      }: {
        auctionId: number;
        patientId?: number;
      }) {
        const isAuctionRoute = checkAuctionRoute({
          pathname: location.pathname,
        });
        if (isAuctionRoute) {
          const nextPathname = navigateBetweenPatientAuctions({
            pathname: location.pathname,
            auctionId,
          });
          navigate({ pathname: nextPathname, search });
        } else {
          navigate({
            pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}`,
            search,
          });
        }
      },
      replaceByHome(patientId: number, auctionId: number) {
        navigate(
          {
            pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}`,
            search,
          },
          { replace: true },
        );
      },
      replaceByProfile(patientId: number, auctionId: number) {
        navigate(
          {
            pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/profile`,
            search,
          },
          { replace: true },
        );
      },
      replaceByassessmentSlug(
        assessmentSlug: AssessmentSlug,
        patientId: number,
        auctionId: number,
      ) {
        navigate(
          {
            pathname: `${dashboardRoute}/patient/${patientId}/auction/${auctionId}/assessing/${assessmentSlug}`,
            search,
          },
          { replace: true },
        );
      },
      ...goToPatientHomeHandlers({
        navigate,
        search,
      }),
    },
    replaceByAuth() {
      navigate(
        {
          pathname: `/auth`,
          search,
        },
        { replace: true },
      );
    },
  };
}

export default getCareseekerNavigationHandlers;
