import { WESER_EMS_MARKETING_URL } from "@recare/core/consts";
import { useTranslations } from "@recare/translations";
import { AppBar, LeftPart, LogoWrapper } from "ds/components/AppBar";
import { RecareNameLogo } from "ds/components/RecareLogo";
import { WHITE } from "ds/materials/colors";
import { SMALL, dp, responsiveCSS } from "ds/materials/metrics";
import { Subheading } from "ds/materials/typography";
import Image from "dsl/atoms/Image";
import { useB2CNavigationUrls } from "dsl/hooks/useNavigationHandlers";
import { B2CFilters } from "dsl/organisms/Filters/B2CApp/types";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const WeserEmsLogoWrapper = ({
  children,
  filters,
}: {
  children: React.ReactElement[];
  filters: B2CFilters;
}) => {
  const location = useLocation();
  const { goToDashboard } = useB2CNavigationUrls();
  const shouldLinkToMarketingWebsite =
    !!location.pathname.includes("onboarding");

  return shouldLinkToMarketingWebsite ? (
    <LogoWrapper href={WESER_EMS_MARKETING_URL} target="_blank">
      {children}
    </LogoWrapper>
  ) : (
    <LogoWrapper to={goToDashboard({ filters })}> {children} </LogoWrapper>
  );
};

export default function Header({ filters }: { filters: B2CFilters }) {
  const translations = useTranslations();

  return (
    <AppBar>
      <LeftPart>
        <WeserEmsLogoWrapper filters={filters}>
          <Image
            style={{ width: dp(34), height: dp(30) }}
            path="Gewinet_Logo.png"
            alt="gewinet-logo"
          />
          <HeaderSubHeading>{translations.bToC.navbar.title}</HeaderSubHeading>
        </WeserEmsLogoWrapper>
      </LeftPart>
      <Separator />
      <RecareNameLogo size={SMALL} style={{ alignSelf: "center" }} />
    </AppBar>
  );
}
const SUBHEADING_SIZE = 12;
const HeaderSubHeading = styled(Subheading)`
  color: ${WHITE};
  ${responsiveCSS({
    mobile: [`font-size: ${SUBHEADING_SIZE}px`],
  })};
`;

const Separator = styled.div`
  background: ${WHITE};
  opacity: 0.5;
  width: ${dp(1)};
  height: ${dp(24)};
  align-self: center;
  margin-right: ${dp(12)};
`;
