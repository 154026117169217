import Translations from "@recare/translations/types";
import ErrorPage from "dsl/ecosystems/ErrorPage";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useOnLogout } from "reduxentities/actions";

export const UnsupportedBrowser = () => {
  const onLogout = useOnLogout();

  useEffect(() => {
    onLogout();
  }, []);

  return (
    <ErrorPage
      getErrorLabel={(translations) => translations.general.errorNotSupportIE}
    />
  );
};

const useGetErrorTranslation = (): ((translations: Translations) => string) => {
  const [search] = useSearchParams();

  return (translations: Translations) => {
    const reason = search.get("reason");

    if (reason === "429") {
      return translations.providersearch.errorTooManyRequests;
    }
    console.error(
      `no error translation set up in GenericErrorPage for error ${reason}`,
    );
    return translations.patient.errorLoadingUrlChanges;
  };
};

export const GenericErrorPage = () => {
  const errorTranslation = useGetErrorTranslation();
  return <ErrorPage getErrorLabel={errorTranslation} />;
};
