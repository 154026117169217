import ButtonBase from "@mui/material/ButtonBase";
import {
  B2C_PRIMARY_COLOR,
  BORDER_BOTTOM,
  ICON_DARK,
  WHITE,
} from "ds/materials/colors";
import {
  border,
  dp,
  margin,
  padding,
  responsiveCSS,
  sizing,
} from "ds/materials/metrics";
import { SHADOW_DP1 } from "ds/materials/shadows";
import { Body } from "ds/materials/typography";
import React from "react";
import styled, { css } from "styled-components";

const DefaultTile = styled(ButtonBase)`
  border: ${border({ color: BORDER_BOTTOM, important: true })};
  box-shadow: ${SHADOW_DP1}!important;
  background-color: ${WHITE}!important;
  width: ${dp(163)}!important;
  height: ${sizing(7)}!important;
  margin: ${margin(1)}!important;
  padding: ${padding(0, 1)}!important;
`;

export const TILE_WIDTH = dp(240);
const B2CTile = styled(ButtonBase)`
  background-color: ${B2C_PRIMARY_COLOR}!important;
  box-shadow: none;
  border: ${border({ width: 2, color: B2C_PRIMARY_COLOR, important: true })};
  margin: ${margin(1, 1)}!important;
  padding: ${padding(2, 2)}!important;
  width: ${TILE_WIDTH}!important;
  height: ${sizing(7)};
  ${({ $hasIcon }: { $hasIcon?: boolean }) =>
    !$hasIcon &&
    css`
      justify-content: center !important;
    `}
  span {
    font-size: ${sizing(2)} !important;
  }
  span,
  svg {
    color: ${WHITE}!important;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${WHITE}!important;
      span,
      svg {
        color: ${B2C_PRIMARY_COLOR}!important;
      }
    }
  }
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  ${responsiveCSS({
    mobile: [
      `align-self: center`,
      `width: calc(100% - ${sizing(2)})!important`,
    ],
    tablet: [`align-self: center`],
    desktop: [`align-self: flex-start`],
  })};
`;

export const TILETYPE = {
  DEFAULT: "DEFAULT",
  B2C: "B2C",
} as const;
const TILES = {
  DEFAULT: DefaultTile,
  B2C: B2CTile,
} as const;

export default function Tile({
  onClick,
  label,
  Icon,
  tileType = TILETYPE.DEFAULT,
}: {
  Icon?: React.ComponentType<any>;
  label: string;
  onClick: () => void;
  tileType?: keyof typeof TILES;
}) {
  const TileWrapper = TILES[tileType];
  return (
    <TileWrapper
      onClick={onClick}
      style={{
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      $hasIcon={!!Icon}
    >
      {Icon && (
        <Icon
          htmlColor={ICON_DARK}
          style={{
            width: sizing(2),
            height: sizing(2),
          }}
        />
      )}
      <Body margin={margin(0, 1)}>{label}</Body>
    </TileWrapper>
  );
}
