import {
  DOCUMENT_PRIVACY_POLICY,
  PFLEGEPORTAL_WEBSITE,
  WESER_EMS_IMPRESSUM_URL,
} from "@recare/core/consts";
import { useTranslations } from "@recare/translations";
import { RecareNameLogo } from "ds/components/RecareLogo";
import { TEXT_DARK_HINT, WHITE } from "ds/materials/colors";
import {
  HorizontalLayout,
  ResponsiveLayout,
  VerticalLayout,
} from "ds/materials/layouts";
import {
  SMALL,
  Z_INDEX_APP_BAR,
  dp,
  margin,
  sizing,
} from "ds/materials/metrics";
import {
  Caption,
  FONT_SIZE_10,
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_REGULAR,
  LINE_HEIGHT_16,
  Link,
} from "ds/materials/typography";
import Image from "dsl/atoms/Image";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { useHover } from "dsl/hooks";
import styled from "styled-components";

export const FOOTER_HEIGHT = 88;

const FooterWrapper = styled(HorizontalLayout)`
  display: flex;
  width: 100vw;
  height: ${dp(FOOTER_HEIGHT)};
  align-items: center;
  justify-content: center;
  background: ${WHITE};
  z-index: ${Z_INDEX_APP_BAR - 1};
`;

const StyledLink = styled(Link)`
  font-size: ${FONT_SIZE_10};
  font-weight: ${FONT_WEIGHT_REGULAR};
  color: ${TEXT_DARK_HINT};
  line-height: ${LINE_HEIGHT_16};
`;

const StyledCaption = styled(Caption)`
  font-size: ${FONT_SIZE_10};
  font-weight: ${FONT_WEIGHT_BOLD};
  color: ${TEXT_DARK_HINT};
  margin: ${margin(0)};
  line-height: ${LINE_HEIGHT_16};
`;

function LogoSection() {
  const { isMobile } = useMedia();
  const translations = useTranslations();
  const { isHover, onHoverProps } = useHover();
  const logoStyle = {
    filter: "grayscale(1) invert(0.6)",
    width: isMobile ? sizing(7) : sizing(12),
    height: isMobile ? sizing(3) : sizing(7),
    cursor: isHover ? "pointer" : "auto",
  };
  return (
    <ResponsiveLayout>
      <Image
        alt="pflegeportal-logo"
        style={{
          ...logoStyle,
          margin: isMobile ? margin(1) : margin(1, 1, 1, 4),
        }}
        path="pflegeportal-logo.png"
        onClick={() => window.open(PFLEGEPORTAL_WEBSITE, "_blank")}
        {...onHoverProps}
      />
      <RecareNameLogo
        size={SMALL}
        onClick={() => window.open(translations.general.websiteUrl, "_blank")}
        style={{ ...logoStyle, margin: "auto 0" }}
        {...onHoverProps}
      />
    </ResponsiveLayout>
  );
}

function AboutSection() {
  const translations = useTranslations();
  const { openDocumentUrl } = useLegalDocuments();
  return (
    <VerticalLayout margin={margin(0, 1.5)}>
      <StyledCaption>{translations.bToC.footer.aboutRecare}</StyledCaption>
      <StyledLink
        onClick={() => window.open(translations.general.websiteUrl, "_blank")}
      >
        {translations.bToC.footer.website}
      </StyledLink>
      <StyledLink
        onClick={() => window.open(WESER_EMS_IMPRESSUM_URL, "_blank")}
      >
        {translations.bToC.footer.imprint}
      </StyledLink>
      <StyledLink onClick={() => openDocumentUrl(DOCUMENT_PRIVACY_POLICY)}>
        {translations.bToC.footer.privacyPolicy}
      </StyledLink>
    </VerticalLayout>
  );
}

function ContactInfoSection() {
  const translations = useTranslations();

  return (
    <VerticalLayout margin={margin(0, 1.5)}>
      <StyledCaption>{translations.bToC.footer.support}</StyledCaption>
      <StyledLink href="tel:03056796057">030 56796057</StyledLink>
      <StyledLink href="mailto:info@recaresolutions.com" target="_blank">
        info@recaresolutions.com
      </StyledLink>
    </VerticalLayout>
  );
}
export default function Footer() {
  return (
    <FooterWrapper>
      <HorizontalLayout width="95%" aligned justify="space-between">
        <LogoSection />
        <HorizontalLayout>
          <AboutSection />
          <ContactInfoSection />
        </HorizontalLayout>
      </HorizontalLayout>
    </FooterWrapper>
  );
}
