import AlarmIcon from "@mui/icons-material/Alarm";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import {
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
  TRACK_EVENTS,
} from "@recare/core/consts";
import Tile, { TILETYPE } from "ds/components/TileButton";
import { USER_FILTER_SOLUTIONS } from "dsl/organisms/Filters/consts";
import OnboardingCard, {
  PAGES,
  PageProps,
  StyledCaption,
  testIds,
} from "../common";
import { useTracking } from "react-tracking";

export const TERM_CHOICES = [
  SOLUTION_SHORT_TERM_STATIC_CARE,
  SOLUTION_STATIC_CARE,
];

const getTrackingTranslation = (solutions: Array<number>) => {
  const isTemporary = solutions.includes(SOLUTION_SHORT_TERM_STATIC_CARE);
  const isLongterm = solutions.includes(SOLUTION_STATIC_CARE);

  if (isTemporary && isLongterm) {
    return undefined;
  } else if (isTemporary) {
    return "temporary";
  } else if (isLongterm) {
    return "longterm";
  }
};

export default function Term({
  navigateToPage,
  setFilters,
  translations,
}: PageProps) {
  const { trackEvent } = useTracking();

  const onAction = (solutions: Array<number>) => {
    navigateToPage(PAGES.LOCATION);
    trackEvent({
      name: TRACK_EVENTS.DASHBOARD_FILTERS,
      search_target: getTrackingTranslation(solutions),
    });
    setFilters({ type: USER_FILTER_SOLUTIONS, value: solutions });
  };
  return (
    <OnboardingCard
      title={translations.bToC.onboardingScreens.titleTemporaryOrLongterm}
      onNext={() => onAction(TERM_CHOICES)}
      navigateToPage={navigateToPage}
      data-testid={testIds.careTerm}
    >
      <StyledCaption>
        {translations.bToC.onboardingScreens.yourChoice}:
      </StyledCaption>
      <Tile
        label={translations.bToC.onboardingScreens.buttonTemporary}
        Icon={AlarmIcon}
        onClick={() => onAction([SOLUTION_SHORT_TERM_STATIC_CARE])}
        tileType={TILETYPE.B2C}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonLongterm}
        Icon={HourglassEmptyIcon}
        onClick={() => onAction([SOLUTION_STATIC_CARE])}
        tileType={TILETYPE.B2C}
      />
    </OnboardingCard>
  );
}
