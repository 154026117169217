import {
  APP_B2C,
  APP_PROVIDER_SEARCH,
  FEDERAL_STATES,
  MAX_PROVIDER_SEARCH_DISTANCE_KM,
  PAGINATION_COUNT_10,
  PAGINATION_RESULT_COUNTS,
  PAGINATION_START_0,
} from "@recare/core/consts";
import { ValueOf } from "@recare/core/types";
import { useLazyGetAddressFromZipcode } from "apollo/hooks/queries";
import { useEnvContext } from "context/EnvContext";
import { fromUnixTime, getUnixTime, isPast, isToday, isValid } from "date-fns";
import usePersistQueryParam from "dsl/hooks/usePersistQueryParam";
import {
  B2CFilterActions,
  B2CFilters,
} from "dsl/organisms/Filters/B2CApp/types";
import { DEFAULT_PROVIDER_SEARCH_FILTER_DISTANCE } from "dsl/organisms/Filters/ProviderSearchApp/initialState";
import {
  ProviderSearchFilterActions,
  ProviderSearchFilters,
} from "dsl/organisms/Filters/ProviderSearchApp/types";
import fromFilterQueryString from "dsl/organisms/Filters/utils/fromQueryString";
import toFilterQueryString from "dsl/organisms/Filters/utils/toQueryString";
import {
  Location,
  URLSearchParamsInit,
  useLocation,
  useSearchParams,
} from "react-router-dom";

export type GenericProviderSearchFilters = B2CFilters | ProviderSearchFilters;

export type GenericProviderSearchActions =
  | B2CFilterActions
  | ProviderSearchFilterActions;

const useGetFilterSanitizer = <State extends GenericProviderSearchFilters>({
  allowedFederalState,
}: {
  allowedFederalState: ValueOf<typeof FEDERAL_STATES>;
}) => {
  const getZipcode = useLazyGetAddressFromZipcode();
  const { app } = useEnvContext();
  const isProviderSearchApp = app === APP_PROVIDER_SEARCH;
  const isB2CApp = app === APP_B2C;
  const [, setSearch] = useSearchParams();

  return (currentSearchString: string, initialState: State) => {
    const filters =
      fromFilterQueryString<State>(currentSearchString) ?? initialState;

    getZipcode(filters.zipcode?.value).then((address) => {
      if (address) {
        filters.zipcode = {
          label: `${address.zipcode}, ${address.city}, ${address.federal_state}`,
          latitude: address.latitude,
          longitude: address.longitude,
          value: address.zipcode!,
        };
      }
      const shouldUpdateStartDate =
        !filters.start_date ||
        (filters.start_date &&
          (!isValid(filters.start_date) ||
            (!isToday(fromUnixTime(filters.start_date)) &&
              isPast(fromUnixTime(filters.start_date)))));

      const shouldUpdateZipcode =
        address?.federal_state !== allowedFederalState;

      const shouldUpdateRadius =
        !filters?.distance ||
        filters.distance > MAX_PROVIDER_SEARCH_DISTANCE_KM;

      const shouldUpdatePaginationCount =
        isProviderSearchApp &&
        (!filters?.count ||
          !PAGINATION_RESULT_COUNTS.includes(
            filters.count as (typeof PAGINATION_RESULT_COUNTS)[number],
          ));

      const shouldUpdatePaginationStart =
        isProviderSearchApp && filters?.start == null;

      if (shouldUpdatePaginationCount) {
        if (filters?.count)
          console.warn(
            `user tried to set provider search count filter to ${filters.count} in query params; resetting...`,
          );
        filters.count = PAGINATION_COUNT_10;
      }

      if (shouldUpdatePaginationStart) {
        filters.start = PAGINATION_START_0;
      }

      if (shouldUpdateStartDate) {
        filters.start_date = getUnixTime(new Date());
      }

      if (shouldUpdateRadius) {
        if (filters.distance)
          console.warn(
            `user tried to set provider search distance filter over ${MAX_PROVIDER_SEARCH_DISTANCE_KM}km in query params; resetting...`,
          );
        filters.distance = DEFAULT_PROVIDER_SEARCH_FILTER_DISTANCE;
      }

      if (shouldUpdateZipcode) {
        filters.zipcode = isB2CApp
          ? {
              label: "49661",
              value: "49661",
              id: "49661",
            }
          : undefined;
        console.warn(
          `user tried to set provider search zipcode filter in ${address?.federal_state} outside of allowed federal states ${allowedFederalState} in query params; resetting...`,
        );
      }

      if (
        shouldUpdateRadius ||
        shouldUpdateStartDate ||
        shouldUpdateZipcode ||
        shouldUpdatePaginationCount ||
        shouldUpdatePaginationStart
      ) {
        const normalisedQueryArguments = toFilterQueryString(filters);
        setSearch(normalisedQueryArguments, { replace: true });
      }
    });

    return filters;
  };
};

function initializer<State extends GenericProviderSearchFilters>(
  location: Location,
  setSearch: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined,
  ) => void,
  sanitizer: (currentSearchString: string, initialState: State) => State,
) {
  return (initialState: State): State => {
    const currentSearchString = location.search;
    if (!currentSearchString) {
      const filterDefaultQueryArguments = toFilterQueryString(initialState);
      setSearch(filterDefaultQueryArguments, { replace: true });
      return initialState;
    }

    return sanitizer(currentSearchString, initialState);
  };
}

export function usePersistQueryParamProviderSearch<
  State extends GenericProviderSearchFilters,
  Actions extends GenericProviderSearchActions,
>(
  id: string,
  reducerFn: (state: State, action: Actions) => State,
  initialState: State,
  allowedFederalState: ValueOf<typeof FEDERAL_STATES>,
) {
  const location = useLocation();
  const [, setSearch] = useSearchParams();
  const sanitizer = useGetFilterSanitizer<State>({
    allowedFederalState,
  });

  return usePersistQueryParam<State, Actions>(
    id,
    reducerFn,
    initialState,
    toFilterQueryString,
    initializer<State>(location, setSearch, sanitizer),
  );
}
