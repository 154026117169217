import HomeIcon from "@mui/icons-material/Home";
import HotelIcon from "@mui/icons-material/Hotel";
import {
  SERVICE_BASIC_CARE,
  SERVICE_BASIC_MEDICAL_CARE,
  SOLUTION_HELP_AT_HOME,
  TRACK_EVENTS,
} from "@recare/core/consts";
import Tile, { TILETYPE } from "ds/components/TileButton";
import {
  USER_FILTER_SERVICES,
  USER_FILTER_SOLUTIONS,
} from "dsl/organisms/Filters/consts";
import { useTracking } from "react-tracking";
import OnboardingCard, {
  PageProps,
  PAGES,
  StyledCaption,
  testIds,
} from "../common";
import { TERM_CHOICES } from "./Term";

const AT_HOME = "at home";
const CARE_HOME = "care home";

export default function CareType({
  navigateToPage,
  setFilters,
  translations,
}: PageProps) {
  const { trackEvent } = useTracking();

  const gotoNext = (target?: typeof AT_HOME | typeof CARE_HOME) => {
    if (!target) {
      navigateToPage(PAGES.LOCATION);
      setFilters({
        type: USER_FILTER_SOLUTIONS,
        value: [SOLUTION_HELP_AT_HOME, ...TERM_CHOICES],
      });
      setFilters({
        type: USER_FILTER_SERVICES,
        value: [SERVICE_BASIC_CARE, SERVICE_BASIC_MEDICAL_CARE],
      });
    } else {
      navigateToPage(
        target === CARE_HOME ? PAGES.CARE_TERM : PAGES.CARE_SOLUTION,
      );
    }
    trackEvent({
      name: TRACK_EVENTS.DASHBOARD_FILTERS,
      search_target: target,
    });
  };
  return (
    <OnboardingCard
      title={translations.bToC.onboardingScreens.titleWhichCare}
      navigateToPage={navigateToPage}
      onNext={gotoNext}
      data-testid={testIds.careType}
    >
      <StyledCaption>
        {translations.bToC.onboardingScreens.yourChoice}:
      </StyledCaption>
      <Tile
        label={translations.bToC.onboardingScreens.buttonCareHome}
        Icon={HotelIcon}
        onClick={() => gotoNext(CARE_HOME)}
        tileType={TILETYPE.B2C}
      />
      <Tile
        label={translations.bToC.onboardingScreens.buttonAtHome}
        Icon={HomeIcon}
        onClick={() => gotoNext(AT_HOME)}
        tileType={TILETYPE.B2C}
      />
    </OnboardingCard>
  );
}
