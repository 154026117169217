import { APP_B2C } from "@recare/core/consts";
import RenderApp from "containers/Appv4";
import ServiceFactory, { Services } from "containers/ServiceFactory";
import Router from "./Router";

export type B2CContext = ReturnType<typeof Context>;

const Context = (services: Services) => ({
  app: APP_B2C,
  services,
});

const services = ServiceFactory("B2C");
const context = Context(services);
RenderApp(context, Router);
