import { PAGINATION_COUNT, PAGINATION_START } from "@recare/core/consts";
import { convertMetertoKm } from "@recare/core/model/utils/location";
import {
  USER_FILTER_AGE_INTERVAL,
  USER_FILTER_CARESEEKERS_SINGULAR,
  USER_FILTER_CARE_LEVELS_SINGULAR,
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  USER_FILTER_ELECTIVE,
  USER_FILTER_GENDERS_SINGULAR,
  USER_FILTER_HAS_CAPACITY,
  USER_FILTER_PATIENT_TYPES_SINGULAR,
  USER_FILTER_SENDERS_SINGULAR,
  USER_FILTER_SERVICES_SINGULAR,
  USER_FILTER_SOCIAL_WORKERS_SINGULAR,
  USER_FILTER_SOLUTIONS_SINGULAR,
  USER_FILTER_SPECIALIZATIONS_SINGULAR,
  USER_FILTER_START_DATE,
  USER_FILTER_START_DATE_PARAM_FROM,
  USER_FILTER_START_DATE_PARAM_TO,
  USER_FILTER_START_DATE_RANGE,
  USER_FILTER_STATIONS_SINGULAR,
  USER_FILTER_TAB,
  USER_FILTER_TRANSITIONAL_CARE,
  USER_FILTER_TREATMENT_LOCATION,
  USER_FILTER_WEIGHT_INTERVAL,
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
} from "../../consts";
import { GenericFilters, ParamFilterName, UserFilters } from "../../types";
import {
  getBasicFilterParamToArray,
  getBooleanQueryParam,
  getIntervalQueryParam,
  getKeyNumberQueryParam,
  getKeyStringQueryParam,
  getSendersQueryParam,
  getTreatmentLocationQueryParam,
} from "./helpers";

export type FilterParamEntries = IterableIterator<[ParamFilterName, string]>;

export default function fromFilterQueryString<Filters extends GenericFilters>(
  queryString: string,
) {
  const urlParams = new URLSearchParams(queryString);
  const entries = urlParams.entries() as FilterParamEntries;

  const filters: Partial<UserFilters> = {};

  for (const [filterName, endcodedValue] of entries) {
    const paramValue = decodeURIComponent(endcodedValue);
    switch (filterName) {
      case USER_FILTER_START_DATE:
      case PAGINATION_COUNT:
      case PAGINATION_START:
        getKeyNumberQueryParam({ filterName, filters, paramValue });
        break;
      case USER_FILTER_DISTANCE_FROM_PROVIDER:
        filters[filterName] = convertMetertoKm(parseInt(paramValue));
        break;
      case USER_FILTER_TAB:
        getKeyStringQueryParam({ filterName, filters, paramValue });
        break;
      case USER_FILTER_SOLUTIONS_SINGULAR:
      case USER_FILTER_CARESEEKERS_SINGULAR:
      case USER_FILTER_GENDERS_SINGULAR:
      case USER_FILTER_CARE_LEVELS_SINGULAR:
      case USER_FILTER_PATIENT_TYPES_SINGULAR:
      case USER_FILTER_SPECIALIZATIONS_SINGULAR:
      case USER_FILTER_STATIONS_SINGULAR:
      case USER_FILTER_SOCIAL_WORKERS_SINGULAR:
      case USER_FILTER_SERVICES_SINGULAR:
        getBasicFilterParamToArray({
          filterName,
          filters,
          paramValue,
        });
        break;
      case USER_FILTER_TREATMENT_LOCATION:
        getTreatmentLocationQueryParam({ filters, paramValue });
        break;
      case USER_FILTER_WEIGHT_INTERVAL:
      case USER_FILTER_AGE_INTERVAL:
        getIntervalQueryParam({ filterName, filters, paramValue });
        break;
      case USER_FILTER_START_DATE_PARAM_FROM:
        {
          const startDateFilter = filters[USER_FILTER_START_DATE_RANGE] || {
            from_date: null,
            to_date: null,
          };
          filters[USER_FILTER_START_DATE_RANGE] = {
            ...startDateFilter,
            from_date: parseInt(paramValue),
          };
        }
        break;
      case USER_FILTER_START_DATE_PARAM_TO:
        {
          if (parseInt(paramValue)) {
            const startDateFilter = filters[USER_FILTER_START_DATE_RANGE] || {
              from_date: null,
              to_date: null,
            };
            filters[USER_FILTER_START_DATE_RANGE] = {
              ...startDateFilter,
              to_date: parseInt(paramValue),
            };
          }
        }
        break;
      case USER_FILTER_TRANSITIONAL_CARE:
      case USER_FILTER_ELECTIVE:
      case USER_FILTER_WITH_CONSULTANTS:
      case USER_FILTER_WITH_PROVIDERS:
      case USER_FILTER_HAS_CAPACITY:
        getBooleanQueryParam({ filterName, filters });
        break;
      case USER_FILTER_SENDERS_SINGULAR:
        getSendersQueryParam({ filters, paramValue });
        break;

      default:
        break;
    }
  }
  if (!Object.keys(filters).length) {
    return;
  }
  return filters as Filters;
}
