import { B2C_PRIMARY_COLOR, STEPPER_INACTIVE } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import {
  border,
  margin,
  padding,
  responsiveCSS,
  sizing,
} from "ds/materials/metrics";
import { Fragment } from "react";
import styled, { css } from "styled-components";
import { PAGES, PAGE_MAX_WIDTH, Page } from "./common";

export const STEPS: Page[][] = [
  [PAGES.TARGET],
  [PAGES.START_DATE],
  [PAGES.CARE_TYPE],
  [PAGES.CARE_SOLUTION, PAGES.CARE_TERM],
  [PAGES.LOCATION],
];
const getActiveIndex = (activePage: Page) =>
  STEPS.findIndex((step) => step.includes(activePage));
const isStepActive = (activePage: Page, currentIndex: number) =>
  currentIndex <= getActiveIndex(activePage);
export default function Stepper({ activePage }: { activePage: Page }) {
  const showStepper = STEPS.some((step) =>
    step.find((page) => page === activePage),
  );
  if (!showStepper) return null;
  return (
    <StepperWrapper>
      {STEPS.map((_, index) => (
        <Fragment key={index}>
          <Circle isActive={isStepActive(activePage, index)} />
          {index < STEPS.length - 1 && (
            <Line isActive={isStepActive(activePage, index + 1)} />
          )}
        </Fragment>
      ))}
    </StepperWrapper>
  );
}

const StepperWrapper = styled(HorizontalLayout)`
  max-width: ${PAGE_MAX_WIDTH};
  position: relative;
  width: 100%;
  height: ${sizing(1.5)};
  margin: ${margin(0, 3)};
  padding: ${padding(5.5, 0)};
  align-items: center;
  justify-content: center;
  ${responsiveCSS({
    mobile: [
      `padding: ${padding(3, 0)}`,
      `justify-content: center`,
      `max-width: ${sizing(37.5)}`,
    ],
    tablet: [`padding: ${padding(5, 0)}`, `justify-content: center`],
    desktop: [`padding: ${padding(5, 0)}`, `justify-content: flex-start`],
  })};
`;
const Circle = styled.div`
  width: ${sizing(1.5)};
  height: ${sizing(1.5)};
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  ${({ isActive }: { isActive?: boolean }) =>
    isActive
      ? css`
          background: ${B2C_PRIMARY_COLOR};
          border: ${border({ color: B2C_PRIMARY_COLOR })};
        `
      : css`
          background: transparent;
          border: ${border({ color: STEPPER_INACTIVE })};
          opacity: 0.2;
        `};
`;
const Line = styled.div`
  width: ${sizing(9)};
  height: ${sizing(0.25)};
  ${({ isActive }: { isActive?: boolean }) =>
    isActive
      ? css`
          background: ${B2C_PRIMARY_COLOR};
        `
      : css`
          background: ${STEPPER_INACTIVE};
          opacity: 0.2;
        `};
`;
