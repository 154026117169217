import {
  APP_B2C,
  APP_PROVIDER_SEARCH,
  PROVIDER_SEARCH_BAVARIA,
  PROVIDER_SEARCH_WESER_EMS,
} from "@recare/core/consts";
import Config, {
  ENV_PRODUCTION,
  getApp,
  getBackendConfig,
} from "@recare/core/model/config";
import { DEFAULT_LOCAL_STORAGE_KEY, Session } from "@recare/core/model/session";
import { logNetEvent } from "@recare/core/model/utils/browser/NetDebugger";
import { isTest } from "@recare/core/model/utils/featureFlags";
import { AnyObject, AppType, ProviderSearch, ToType } from "@recare/core/types";
import { getUnixTime } from "date-fns";
import "whatwg-fetch";
import { getIgnoreTransportErrorReason } from "./shared";

declare global {
  interface Window {
    _test_token_expiration: string;
  }
}

type tokenAccessorType = () => string | undefined;
export let tokenAccessor: tokenAccessorType = () => undefined;

type authAccessorType = () => any | undefined;

export let authAccessor: authAccessorType = () => undefined;

type tokenExpirationType = () => number | undefined;
export let tokenExpiration: tokenExpirationType = () => undefined;

export function setTokenAccessor(newTokenAccessor: tokenAccessorType): void {
  tokenAccessor = newTokenAccessor;
}

export function setAuthAccessor(newAuthAccessor: authAccessorType): void {
  authAccessor = newAuthAccessor;
}

export function setTokenExpiration(
  newTokenExpiration: tokenExpirationType,
): void {
  tokenExpiration = newTokenExpiration;
}

type Options = {
  file?: any;
  gqlFragmentHeader?: string;
  isApollo?: boolean;
  routeName?: string;
  token?: string;
  url?: string;
};

export function shouldRetry(
  route: string,
  statusCode: number | undefined,
): boolean {
  if (route.startsWith("/auth")) return false;
  if (statusCode === 404) return false;

  return true;
}

export function shouldLogoutUnauthorized(
  route: string,
  statusCode: number | undefined,
): boolean {
  if (route.startsWith("/auth")) return false;
  if ((window as any).IN_STORYBOOK) return false;
  return statusCode === 401;
}

function getStatusCode(error: ToType): number | undefined {
  try {
    const json = error?.message && JSON.parse(error.message);
    return json?.status;
  } catch (e) {
    return undefined;
  }
}

const defaultMessage = "Failed request";

function redacAuthToken(authToken: string) {
  if (authToken.startsWith("Bearer ")) return "Bearer REDACTED";
  return authToken;
}

export function trackError(
  route: string,
  status: number,
  body: any,
  auth: string | undefined,
): void {
  const identification = authAccessor();
  const transportReason = getIgnoreTransportErrorReason(status, body, route);
  const message = body.message || defaultMessage;

  const authPart: AnyObject | { auth: string } = auth
    ? { auth: redacAuthToken(auth) }
    : {};
  const context = {
    ...authPart,
    transportReason,
    email: identification?.account?.email || "",
    id: identification?.account?.id?.toString() || "",
    status: status.toString(),
    route,
    body: JSON.stringify(body),
    retry: body.isRetry ? "true" : "false",
  };

  console.error(message, context);
}

export function fetch({
  fetchBody,
  headers,
  isApollo,
  isRetry = false,
  method,
  routeName,
  url,
}: {
  fetchBody: FormData | string | undefined;
  headers: AnyObject;
  isApollo: boolean | undefined;
  isRetry?: boolean;
  method: string;
  routeName: string | undefined;
  url: string;
}): Promise<any> {
  return window
    .fetch(url, {
      method,
      headers,
      body: fetchBody,
    })
    .then(async (response: Response) => {
      if (response.status !== 200) {
        if (isApollo && response.status === 404) {
          throw new Error("Error 404 not found");
        }

        let body;
        try {
          body = await response.clone().json();
        } catch (e) {
          // console.error(e)
        }

        const error = {
          status: response.status,
          response,
          body,
        };

        // since we let apollo handle error codes internally,
        // we log to datadog manually
        // if using fetch(transport) directly we want to throw the error
        // which will be caught and tracked in the "genericRequest" method
        if (isApollo) {
          trackError(
            routeName || url,
            response.status,
            {
              body,
              message: `Bad HTTP status (${response.status})`,
              isRetry,
              routeName,
              url,
              error,
            },
            headers.Authorization,
          );

          console.error(
            new Error(`${response.statusText} ${JSON.stringify(error)}`),
          );
        } else throw new Error(JSON.stringify(error));
      }

      return isApollo ? response : response.json();
    });
}

export function getBackendRouteUrl(backendConfig: any, route: string) {
  const routeUrl = backendConfig.url
    ? `${backendConfig.url}${route}`
    : `${backendConfig.protocol}://${backendConfig.host || ""}:${
        backendConfig.port || ""
      }${route}`;
  return routeUrl;
}

type ProviderSearchHeader = ProviderSearch | null;

const getProviderSearchHeader = (
  app: AppType,
  // since weserems is being treated as a provider search by the BE we need to send the header
): ProviderSearchHeader => {
  switch (app) {
    case APP_B2C:
      return PROVIDER_SEARCH_WESER_EMS;
    case APP_PROVIDER_SEARCH:
      // TODO: handle multiple provider searches here
      return PROVIDER_SEARCH_BAVARIA;
    default:
      return null;
  }
};

export function genericRequest({
  method,
  route = "",
  queryParams,
  body,
  options = {},
}: {
  body: AnyObject | Array<any> | null | undefined;
  method: "DELETE" | "GET" | "POST" | "PUT";
  options: Options | undefined;
  queryParams: AnyObject | null | undefined;
  route: string;
}): Promise<any> {
  const { file, isApollo, routeName, token, url: optionalUrl } = options;

  let bearer = token || tokenAccessor() || "";

  const jwtInApolloVariables =
    body && "variables" in body && body.variables.jwt;

  if (!bearer && jwtInApolloVariables) {
    bearer = jwtInApolloVariables;
    delete body.variables.jwt;
  }

  if (bearer && queryParams) {
    delete queryParams.token;
  }

  const testExpiration =
    Config.environment !== ENV_PRODUCTION
      ? window._test_token_expiration
      : undefined;
  const expiration = testExpiration ?? tokenExpiration();

  if (
    expiration &&
    Number(expiration) < getUnixTime(new Date()) &&
    !route.startsWith("/auth")
  ) {
    window.location.href = "/logout?reason=session_expired";
    return Promise.resolve(null);
  }

  // default transport requires constructing the url here
  // apollo constructs the route part in the component
  let url = route;

  if (optionalUrl) {
    url = `${optionalUrl}${route}`;
  } else {
    const backendConfig = getBackendConfig();
    if (backendConfig) {
      url = getBackendRouteUrl(backendConfig, route);
    }
  }
  if (queryParams && Object.keys(queryParams).length > 0) {
    // They should be URI Encoded but the status are in this format status=1,2,3
    const query = Object.keys(queryParams)
      .map((key) => {
        if (queryParams?.[key] && Array.isArray(queryParams[key])) {
          return queryParams[key].map((s: string) => `status=${s}`).join("&");
        }

        if (!queryParams) return null;
        return `${key}=${queryParams[key]}`;
      })
      .join("&");
    url = `${url}?${query}`;
  }

  const headers: {
    Accept: string;
    Authorization?: string;
    "Content-Type"?: string;
    "X-Custom-recare-app-id"?: number;
    "X-Custom-recare-app-version"?: string;
    "X-Custom-recare-origin"?: string;
    "X-Custom-recare-provider-search-state"?: ProviderSearchHeader;
    "X-Custom-recare-session-id"?: string;
    "X-Custom-recare-session-item"?: number;
  } = {
    Accept: "*/*",
  };

  let fetchBody: FormData | string | undefined;
  if (file) {
    fetchBody = new FormData();
    fetchBody.append("file", file);
  } else {
    fetchBody = body ? JSON.stringify(body) : undefined;
    headers["Content-Type"] = "application/json";
  }

  if (window?.location?.origin) {
    headers["X-Custom-recare-origin"] = window.location.origin;
  }

  const app = getApp();
  if (app) {
    headers["X-Custom-recare-app-id"] = app;

    const providerSearchHeader = getProviderSearchHeader(app as AppType);
    if (providerSearchHeader != null) {
      headers["X-Custom-recare-provider-search-state"] = providerSearchHeader;
    }
  } else {
    if (!isTest)
      console.error(`no app in generic request`, {
        url,
        location: window?.location,
      });
  }
  if (Config.version) headers["X-Custom-recare-app-version"] = Config.version;

  const session = Session.getSessionFromLocalStorage(DEFAULT_LOCAL_STORAGE_KEY);

  if (session?.id) headers["X-Custom-recare-session-id"] = session.id;
  if (session?.trackingEventsCount)
    headers["X-Custom-recare-session-item"] = session.trackingEventsCount;

  if (bearer != "") headers.Authorization = `Bearer ${bearer}`;

  return fetch({ url, method, headers, fetchBody, routeName, isApollo })
    .catch((errorFirst) => {
      const statusCode = getStatusCode(errorFirst);

      if (shouldLogoutUnauthorized(route, statusCode)) {
        window.location.href = "/logout?reason=unauthorized";
        return Promise.resolve(null);
      }

      if (!shouldRetry(route, statusCode)) throw errorFirst;

      return fetch({
        url,
        method,
        headers,
        fetchBody,
        routeName,
        isApollo,
        isRetry: true,
      }).catch((errorSecond) => {
        let responseStatus;
        try {
          responseStatus = JSON.parse(errorSecond.message).status;
        } catch (e) {
          responseStatus = -1;
        }
        trackError(
          routeName || url,
          responseStatus,
          {
            message: defaultMessage,
            isRetry: true,
            error: errorSecond,
            routeName,
            url,
          },
          headers.Authorization,
        );
        throw errorSecond;
      });
    })
    .then((res) => {
      logNetEvent({ method, url, reqBody: fetchBody, resBody: res || "200" });
      return res;
    })
    .catch((err) => {
      logNetEvent({ method, url, reqBody: fetchBody, err });
      throw err;
    });
}

type getType = {
  options?: Options;
  queryParams?: AnyObject | null | undefined;
  route: string;
};
function get<Response>({
  options,
  queryParams,
  route,
}: getType): Promise<Response> {
  return genericRequest({
    method: "GET",
    route,
    queryParams,
    body: null,
    options,
  });
}

type postType = {
  body: AnyObject | Array<any> | null | undefined;
  options?: Options;
  queryParams?: AnyObject | null | undefined;
  route: string;
};
function post<Response>({
  body,
  options,
  queryParams,
  route,
}: postType): Promise<Response> {
  return genericRequest({
    method: "POST",
    route,
    queryParams,
    body,
    options,
  });
}

type delType = {
  body: AnyObject;
  options?: Options;
  route: string;
};
function del<Response>({ body, options, route }: delType): Promise<Response> {
  return genericRequest({
    method: "DELETE",
    route,
    queryParams: null,
    body,
    options,
  });
}

type putType = {
  body: AnyObject | Array<any>;
  options?: Options;
  queryParams?: AnyObject | null | undefined;
  route: string;
};
function put<Response>({
  body,
  options,
  queryParams,
  route,
}: putType): Promise<Response> {
  return genericRequest({
    method: "PUT",
    route,
    queryParams,
    body,
    options,
  });
}

type uploadFileType = {
  file: File;
  options?: Options;
};
function uploadFile<Response>({
  file,
  options = {},
}: uploadFileType): Promise<Response> {
  return genericRequest({
    method: "POST",
    route: "/upload",
    queryParams: null,
    body: null,
    options: { ...options, file },
  });
}

export default {
  get,
  post,
  del,
  put,
  uploadFile,
  setTokenAccessor,
  setAuthAccessor,
  setTokenExpiration,
};
