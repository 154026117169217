import { PAGINATION_COUNT, PAGINATION_START } from "@recare/core/consts";
import { SearchType } from "@recare/core/types";
import {
  USER_FILTER_AGE_INTERVAL,
  USER_FILTER_DISTANCE_FROM_PROVIDER,
  USER_FILTER_ELECTIVE,
  USER_FILTER_HAS_CAPACITY,
  USER_FILTER_SENDERS,
  USER_FILTER_SENDERS_SINGULAR,
  USER_FILTER_START_DATE,
  USER_FILTER_TAB,
  USER_FILTER_TRANSITIONAL_CARE,
  USER_FILTER_WEIGHT_INTERVAL,
  USER_FILTER_WITH_CONSULTANTS,
  USER_FILTER_WITH_PROVIDERS,
  fromArrayParamName,
} from "../../consts";
import {
  ArrayFilterQueryParamName,
  ParamFilterName,
  UserFilters,
} from "../../types";
import { BasicArrayTypeNames } from "../toQueryString/helpers";

export type GetKeyNumberQueryParamProps = {
  filterName: Extract<
    ParamFilterName,
    | typeof USER_FILTER_DISTANCE_FROM_PROVIDER
    | typeof USER_FILTER_START_DATE
    | typeof PAGINATION_COUNT
    | typeof PAGINATION_START
  >;
  filters: Partial<UserFilters>;
  paramValue: string;
};

export const getKeyNumberQueryParam = ({
  filterName,
  filters,
  paramValue,
}: GetKeyNumberQueryParamProps): void => {
  filters[filterName] = parseInt(paramValue);
};

export type GetKeyStringQueryParamProps = {
  filterName: Extract<ParamFilterName, typeof USER_FILTER_TAB>;
  filters: Partial<UserFilters>;
  paramValue: string;
};

export const getKeyStringQueryParam = ({
  filterName,
  filters,
  paramValue,
}: GetKeyStringQueryParamProps): void => {
  filters[filterName] = paramValue;
};

type BasicArrayParamTypeNames = Exclude<
  ArrayFilterQueryParamName,
  typeof USER_FILTER_SENDERS_SINGULAR
>;

export type GetBasicFilterParamToArrayProps = {
  filterName: Extract<ParamFilterName, BasicArrayParamTypeNames>;
  filters: Partial<UserFilters>;
  paramValue: string;
};

export const getBasicFilterParamToArray = ({
  filterName,
  filters = {},
  paramValue,
}: GetBasicFilterParamToArrayProps): void => {
  const arrayParamName = fromArrayParamName?.[
    filterName
  ] as BasicArrayTypeNames;
  const currentArrayValue = filters[arrayParamName] ?? [];

  filters[arrayParamName] = [
    ...currentArrayValue,
    Number(paramValue),
  ] as SearchType[];
};

export type GetBooleanQueryParamProps = {
  filterName: Extract<
    ParamFilterName,
    | typeof USER_FILTER_ELECTIVE
    | typeof USER_FILTER_TRANSITIONAL_CARE
    | typeof USER_FILTER_HAS_CAPACITY
    | typeof USER_FILTER_WITH_CONSULTANTS
    | typeof USER_FILTER_WITH_PROVIDERS
  >;
  filters: Partial<UserFilters>;
};

export const getBooleanQueryParam = ({
  filterName,
  filters,
}: GetBooleanQueryParamProps): void => {
  filters[filterName] = true;
};

export type GetIntervalQueryParamProps = {
  filterName: Extract<
    ParamFilterName,
    typeof USER_FILTER_WEIGHT_INTERVAL | typeof USER_FILTER_AGE_INTERVAL
  >;
  filters: Partial<UserFilters>;
  paramValue: string;
};

export const getIntervalQueryParam = ({
  filterName,
  filters,
  paramValue,
}: GetIntervalQueryParamProps) => {
  const interval = paramValue.match(/\d+/g);
  if (interval) {
    const min = Number(interval[0]) === 0 ? null : Number(interval[0]);
    const max = Number(interval[1]) === 999 ? null : Number(interval[1]);
    filters[filterName] = { min, max };
  }
};

export type GetTreatmentLocationQueryParamProps = {
  filters: Partial<UserFilters>;
  paramValue: string;
};

export const getTreatmentLocationQueryParam = ({
  filters,
  paramValue,
}: GetTreatmentLocationQueryParamProps) => {
  filters.zipcode = {
    id: paramValue,
    value: paramValue,
    label: paramValue,
  };
};

export type GetSendersQueryParamProps = {
  filters: Partial<UserFilters>;
  paramValue: string;
};

export const getSendersQueryParam = ({
  filters,
  paramValue,
}: GetSendersQueryParamProps) => {
  const currentArrayValue = filters[USER_FILTER_SENDERS] ?? [];
  const [id, careseekerName] = paramValue.split("-");

  filters[USER_FILTER_SENDERS] = [
    ...currentArrayValue,
    {
      id: Number(id),
      label: careseekerName,
      value: id,
    },
  ];
};
