import { Snackbar } from "@mui/material";
import { AnyObject, Notification } from "@recare/core/types";
import { useA11yMessage } from "containers/A11yStatusProvider/A11yStatusContext";
import { InfoBanner } from "ds/components/InfoBanner";
import {
  ComponentType,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

export type UseNotification = ReturnType<typeof useNotification>;

const NotificationContext = createContext<(options: Notification) => void>(
  () => {},
);

export function withNotification<P>(
  WrappedComponent: ComponentType<
    P & { showNotification: (options: Notification) => void }
  >,
) {
  const ComponentWithNotification = (props: P) => {
    return (
      <NotificationContext.Consumer>
        {(showNotification) => (
          <WrappedComponent
            {...(props as unknown as P)}
            showNotification={showNotification}
          />
        )}
      </NotificationContext.Consumer>
    );
  };
  return ComponentWithNotification;
}

export function useNotification() {
  return useContext(NotificationContext);
}

export type NotificationReducer = {
  activeNotification: Notification | undefined;
  counter: number;
  notifications: Array<Notification>;
};

type AddNotif = "add";
type NextNotif = "next";
type ActionType = AddNotif | NextNotif;

export const ADD_NOTIF: AddNotif = "add";
export const NEXT_NOTIF: NextNotif = "next";

const defaultNotificationState: NotificationReducer = {
  counter: 0,
  notifications: [],
  activeNotification: undefined,
};

export function notificationReducer(
  state: NotificationReducer,
  action: { payload?: AnyObject; type: ActionType },
): NotificationReducer {
  switch (action.type) {
    case ADD_NOTIF: {
      const notification = {
        Component: action.payload?.Component,
        message: action.payload?.message,
        type: action.payload?.type,
        a11yType: action.payload?.a11yType ?? "status",
        action: action.payload?.action,
        key: `notification-${state.counter}`,
      };
      if (state.activeNotification == undefined) {
        return {
          ...state,
          activeNotification: notification,
        };
      }

      if (
        state.activeNotification.message !== notification.message &&
        !state.notifications.find((n) => n.message === notification.message)
      ) {
        return {
          ...state,
          counter: state.counter + 1,
          notifications: [
            ...state.notifications,
            action.payload as Notification,
          ],
        };
      }
      return state;
    }
    case NEXT_NOTIF: {
      if (state.notifications.length > 0) {
        const newNotifications = state.notifications;
        const activeNotification = newNotifications.shift();
        return {
          counter: state.counter - 1,
          notifications: newNotifications,
          activeNotification,
        };
      }
      return {
        ...state,
        activeNotification: undefined,
      };
    }
    default: {
      console.error("[NOTIFICATION] Reducer action not found", {
        state,
        action,
      });
      return state;
    }
  }
}

export default function NotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [{ activeNotification }, dispatch] = useReducer(
    notificationReducer,
    defaultNotificationState,
  );
  const showNotification = useCallback(
    (payload: Notification) => dispatch({ type: ADD_NOTIF, payload }),
    [dispatch],
  );
  const nextNotification = useCallback(() => {
    dispatch({ type: NEXT_NOTIF });
  }, [dispatch]);

  const { updateA11yMessage } = useA11yMessage();

  useEffect(() => {
    if (activeNotification?.message.length) {
      updateA11yMessage(
        activeNotification?.message,
        activeNotification?.a11yType,
      );
    }
  }, [activeNotification?.message]);

  const Component = activeNotification?.Component;

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      {!!Component && <Component nextNotification={nextNotification} />}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={8000}
        key={activeNotification?.key}
        onClose={nextNotification}
        open={!!activeNotification && !Component}
      >
        <div>
          <InfoBanner
            message={activeNotification?.message}
            onClose={nextNotification}
            severity={activeNotification?.type}
            shouldFocus={false}
            testId="notification-message"
          />
        </div>
      </Snackbar>
    </NotificationContext.Provider>
  );
}
